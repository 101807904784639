import React from "react";
import "../css/divider.css";

function Divider() {
  return (

    <div className="fixed-bg">
        <div className="empty"></div>
    </div>

  );
};

export default Divider;