import React from 'react'
import ProjectComponent from '../components/Projects'

function Projects() {
    return (
        <div>
            <h1>Projects Page!</h1>
            <ProjectComponent />

        </div>
        
    )
}

export default Projects
